/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; width:100%}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/**
.mat-grid-tile .mat-figure {
    justify-content: flex-start !important ;
    align-items: flex-start !important;
 }
 */
 .file-upload, .acc-creation, .trial-definition{
    align-items:left;
    justify-content:left;
    height: 100%; width:100%;
    margin-left:10px;
 }

 .file-name {
    font-size: 12px
 }

 mat-label {
    font-weight: bold;
 }
